<template>
  <v-card class="custom-card-bg-1" height="100%" width="100%">
    <v-simple-table>
      <v-data-table :headers="headers" :items="data" :items-per-page="5" fixed-header height="225">
        <template v-slot:item.actions="{ item }">
          <v-btn icon small @click="zoomto(item)">
            <v-icon small>
              icon-gps
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.area="{ item }">
          <div v-html="convertArea.transformUnit(item.area)"></div>
        </template>
        <template v-slot:item.vector.acquired_date="{ item }">
          {{ convertDate.dateFormatted(item.vector.acquired_date) }}
        </template>
      </v-data-table>
    </v-simple-table>
  </v-card>
</template>
<script>
import bbox from '@turf/bbox'
import convertDate from '@/utils/convertDate'
import convertArea from '@/utils/convertArea'

export default {
  computed: {
    convertArea() {
      return convertArea
    },
    convertDate() {
      return convertDate
    },
  },
  props: ['data'],
  name: 'management',
  components: {},
  data() {
    return {
      isCreating: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'AOI', value: 'aoi' },
        { text: 'Type', value: 'type' },
        { text: 'Area', value: 'area' },
        { text: 'Result type', value: 'result_type' },
        { text: 'Date', value: 'date' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  methods: {
    zoomto(item) {
      let bounds = bbox(item.geojson)
      this.$emit('zoomto', bounds)
    },
  },
}
</script>
