<template>
  <v-layout class="fill-height overflow-hidden">
    <div style="flex: 1">
      <v-layout class="fill-height" column>
        <div class="pb-2 pr-3" style="flex: none; width: 100%; text-align: end">
          <v-menu v-model="filter" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark small v-bind="attrs" v-on="on">
                <v-icon class="mr-2" small>mdi-filter-cog-outline</v-icon>
                Filter Result
              </v-btn>
            </template>
            <v-card :disabled="loading" class="pa-3" style="width: 320px">
              <v-layout class="fill-height" column>
                <div class="py-1">
                  <v-select
                    v-model="filters.service"
                    :items="services"
                    clearable
                    dense
                    hide-details
                    item-text="name"
                    label="Select service"
                    outlined
                    placeholder="Service"
                    return-object
                    @change="getListAOI"
                  />
                </div>
                <div class="py-1">
                  <v-select
                    v-model="filters.currentAOI"
                    :disabled="!filters.service"
                    :items="AOIs"
                    clearable
                    dense
                    hide-details
                    item-text="name"
                    label="Select AOI"
                    outlined
                    placeholder="AOI"
                    return-object
                    @change="!filters.currentAOI ? (filters.currentAOI = {}) : ''"
                  />
                </div>
                <div class="py-1">
                  <DatePicker v-model="filters.fromDate" label="From date" left outlined />
                </div>
                <div class="py-1">
                  <DatePicker v-model="filters.toDate" label="To date" left outlined />
                </div>
                <div class="py-1">
                  <v-select
                    v-model="filters.type"
                    :items="types"
                    dense
                    hide-details
                    item-text="name"
                    label="Select type"
                    outlined
                    placeholder="Type"
                    return-object
                  />
                </div>
                <v-layout class="pt-2">
                  <v-spacer />
                  <v-btn class="mr-2" color="error"  @click="resetFilter">
                    Reset
                  </v-btn>
                  <v-btn color="primary" small  @click="getListData">
                    Apply
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-card>
          </v-menu>
        </div>
        <div style="flex: 1">
          <v-card class="custom-card-bg-1" height="100%" width="100%">
            <Map ref="map" :current-layers="layers" />
            <v-btn
              v-show="!displayLayerControl"
              color="bgContainer"
              fab
              style="position: absolute; bottom: 10px; left: 10px; z-index: 2"
              @click.native.stop="displayLayerControl = true"
            >
              <v-icon>icon-layers</v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
import Map from '@/components/Map.vue'
import utils from '@/utils/genUUID'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { getServices } from '@/api/services'
import { getDataTopResult } from '@/api/change-detection-api'
import { mapState } from '@/store/ults'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import DatePicker from '@/components/DatePicker.vue'
import randomColor from '@/utils/randomColor'

export default {
  name: 'qaMapView',
  components: { DatePicker, DateRangerPicker, LayerControl, Map },
  data() {
    return {
      loading: false,
      filter: false,
      types: [],
      filters: {
        service: {},
        type: undefined,
        currentAOI: {},
        fromDate: undefined,
        toDate: undefined,
      },
      services: [],
      displayLayerControl: true,
      layers: [],
      data: [],
    }
  },
  watch: {
    displayLayerControl() {
      this.$refs.map.reSize()
    },
  },
  computed: {
    ...mapState('AOI', ['AOIs']),
  },
  mounted() {
    this.getService()
    this.getListData()
  },
  methods: {
    async getService() {
      try {
        this.loading = true
        this.services = []
        const res = await getServices({ projectId: this.$route.params.id })
        this.services = res.data.filter(val => val.group === this.$route.meta.service)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        if (!this.filters.service || !Object.keys(this.filters.service).length) {
          this.filters.service = {}
          this.filters.currentAOI = {}
          return
        }
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_id: this.filters.service.id },
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getListData() {
      try {
        this.loading = true
        this.$parent.loading = true
        this.filter = false
        this.data = []
        this.$parent.data = []
        const res = await getDataTopResult({
          projectId: this.$route.params.id,
          payload: {
            aoi_id: this.filters.currentAOI.uuid,
            service_id: this.filters.service.id,
            form_date: this.filters.fromDate,
            to_date: this.filters.toDate,
          },
        })
        this.data = res.data
        this.$parent.data = res.data
        this.removeLayer()
        if (Object.keys(this.filters.currentAOI).length) this.$refs.map.submitZoom(this.filters.currentAOI.bbox)
        this.addLayerToMap(res.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.$parent.loading = false
      }
    },
    zoomto(bounds) {
      this.$refs.map.submitZoom(bounds)
    },
    addLayerToMap(data) {
      this.$refs.map.removeAllLayer()
      data.forEach((item, ind) => {
        item.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: item.geojson,
            },
          ],
        }
        this.$refs.map.addGeoJsonToMap(item.id, item.geojson, randomColor.getColor(ind), utils.getUUID(), 'line', true)
      })
    },
    removeLayer() {
      this.$refs.map.removeAllLayer()
    },
    resetFilter() {
      this.filters = {
        service: {},
        type: undefined,
        currentAOI: {},
        fromDate: undefined,
        toDate: undefined,
      }
      this.getListData()
    },
  },
}
</script>

<style scoped></style>
