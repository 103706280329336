<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pb-4 overflow-hidden" style="flex: 1">
      <mapView ref="mapview" />
    </div>
    <div style="width: 100%; height: 275px; flex: none">
      <management :data.sync="data" @zoomto="bounds => $refs.mapview.zoomto(bounds)"></management>
    </div>
  </v-layout>
</template>

<script>
import mapView from '@/views/change-detection/top-result/Mapview.vue'
import management from '@/views/change-detection/top-result/Management.vue'

export default {
  components: { mapView, management },
  data() {
    return {
      loading: false,
      data: [],
    }
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped></style>
